import Vue from 'vue';
import Router from 'vue-router';
// 同步路由参数
import {
  sync,
} from 'vuex-router-sync';
// vuex 仓库
import store from '../vuex/store';
// 缓存处理
import cache from '../common/cache';
// 路由配置
import routeConfig from './config';

const routerPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error=> error)
}

Vue.use(Router);

// 构造路由
const router = new Router(routeConfig);

// 修改title
function changeTitle(title, src) {
  const tagTitle = document.getElementsByTagName('title')[0];
  tagTitle.innerText = title;
}

// 路由钩子
// S 转场动画
let historyCount = cache.getSessionData('count') * 1 || 0;
cache.setSessionData('/', 0);
const commit = store.commit || store.dispatch;
router.beforeEach((to, from, next) => {
  const meta = to.meta || {};

  // 路由变化，修改title
  if (meta && meta.title) {
    changeTitle(meta.title);
  }
  // 转场动画方向控制
  const toIndex = cache.getSessionData(to.path);
  const fromIndex = cache.getSessionData(from.path);
  if (toIndex) {
    if (toIndex > fromIndex) {
      commit('UPDATE_DIRECTION', 'forward');
    } else {
      commit('UPDATE_DIRECTION', 'reverse');
    }
  } else {
    historyCount += 1;
    cache.setSessionData('count', historyCount);
    to.path !== '/' && cache.setSessionData(to.path, historyCount);
    commit('UPDATE_DIRECTION', 'forward');
  }
  // loading 动画
  if (meta && meta.needLoading) {
    store.dispatch('UPDATE_LOADING', {
      status: true,
      text: '加载中...',
    });
  }

  // 导航条控制
  if (meta) {
    store.commit('UPDATE_NAV', meta.hideNav);
    store.commit('UPDATE_ROUTER', meta.nav || to.path);
  }
  next();
});
sync(store, router);

// E 转场动画

// 页面渲染完毕后关闭loading
router.afterEach((to) => {
  const env = 'replacing_env';
  // 百度 pv 统计
  if (env === 'production') {
    ba.trackPageview(to.path);
  }
  // 关闭loading
  const meta = to.meta || {};
  if (meta && meta.needLoading) {
    store.dispatch('UPDATE_LOADING', {
      status: false,
      time: 500,
    });
  }
});

router.onError((error) => {
  const pattern = /Loading chunk (\w)+ failed/g;
  const isChunkLoadFailed = error.message.match(pattern);
  const targetPath = router.history.pending.fullPath;
  if (isChunkLoadFailed) {
   router.replace(targetPath);
  }
});

export default router;
