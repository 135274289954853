/**
 * 分转元
 * @param val
 */
const fenToYuan = function (val) {
  if (val !== null && val !== undefined) {
    return (parseInt(val, 0) / 100).toFixed(2);
  }
  return '--';
};

/**
 * 分转元没有小数
 * @param val
 */
const fenToYuanNoDecimal = function (val) {
  if (val !== null && val !== undefined) {
    return (parseInt(val, 0) / 100).toFixed(0);
  }
  return '--';
};

/**
 * 分转万元
 * @param val
 */
const fenToWan = function (val) {
  return val ? (val / 1000000).toFixed(2) : '--';
};

export default {
  fenToYuan,
  fenToYuanNoDecimal,
  fenToWan,
};
