







import { Component, Vue } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import Navigation from './components/Nav.vue'
import cache from '@/common/cache';

@Component({
  components: {
    Navigation
  },
})
export default class App extends Vue {
  @Getter('hideNav') hideNav

  isShowlook: boolean = true
  isPositionFixed: boolean = false
  isInitCompleted: boolean = false
  // 不需要检查权限白名单，通过href include 匹配
  readonly whiteList = [
    'login',
  ]

  get redirect () {
    const { redirect } = this.$store.state

    if (redirect) {
      this.$router.push({
        path: redirect
      })
    }
    return false
  }

  get iswhiteUrl () {
    const { href } = window.location
    const isWhite = this.whiteList.some((item) => href.includes(item))
    return isWhite
  }

  created () {
    const { href } = window.location
    if (!this.iswhiteUrl) {
      const token = cache.getLocalStorageData('access_token');
      if (!token) return;
      // 获取用户登录信息
      this.$store.dispatch('UPDATE_USERINFO');
    }
  }
}
