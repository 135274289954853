/**
 * 全局混合
 */
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import util from '@/common/util';
import cache from '@/common/cache'

declare module 'vue/types/vue' {
  interface Vue {
    storeId: any
    hasAuth(auth: any): void
    checkAuth(): any
    showToast(text: string): void
    switchLoading(loading: any): void
    encodeURIComponent(param: any): void
    decodeURIComponent(param: any): void
    openNewTab(url: string): void
    joinTokenToUrl(url: string): string
    formatImages(carImages: any): void
    formatBigImages(carImages: any)
  }
}

@Component({
  filters: {
    formatCarStatus (val) {
      return ['待开拍', '竞拍中', '已拍出', '已流拍', '已成交', '已关闭', '已下架', '待审核', '已驳回'][+val-1]
    }
  }
})
export default class GlobalMixin extends Vue {
  // 校验是否有对应的权限
  hasAuth(auth) {
    const authList = cache.getLocalStorageData('auth_list');
    return authList ? authList.some((val) => val === auth) : []
  }

  // 异步获取权限
  checkAuth() {
    return this.$store.dispatch('UPDATE_USERINFO');
  }

  showToast(text) {
    this.$store.dispatch('UPDATE_TOAST', {
      show: true,
      text,
    });
  }

  switchLoading(loading) {
    this.$store.dispatch('UPDATE_LOADING', {
      status: loading.status || false,
      text: loading.text || '',
      time: loading.time || 0,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  encodeURIComponent(param) {
    // 适配encodeURIComponent方法，将undefined更改为空字符串
    const paramStr = '';
    if (param === undefined) {
      return paramStr;
    }
    return window.encodeURIComponent(param);
  }

  // eslint-disable-next-line class-methods-use-this
  decodeURIComponent(param) {
    // 适配decodeURIComponent方法，将undefined更改为空字符串
    const paramStr = '';
    if (param === undefined) {
      return paramStr;
    }
    return window.decodeURIComponent(param);
  }

  // eslint-disable-next-line class-methods-use-this
  openNewTab(url) {
    const {
      location: {
        protocol,
        host,
      },
    } = window;
    return util.openNewTab(protocol, host, url);
  }

  joinTokenToUrl(url = '') {
    if (typeof url === 'string' && url !== '') {
      const token = cache.getLocalStorageData('access_token')
      if (url.indexOf('?') === -1) {
        url += `?access-token=${token}`
      } else {
        url += `&access-token=${token}`
      }
    }
    return url
  }

  formatImages (carImages) {
    const currentImages = []
    carImages.forEach(item => {
      currentImages.push(item.upload_image);
    });
    return currentImages;
  }

  formatBigImages (urls, name = '') {
    let result = []
    urls.forEach(item => {
      result.push({
        // ...item,
        src: item.image,
        name: name || ''
      })
    });
    return result;
  }
}
