





































import { Component, Vue } from 'vue-property-decorator'
import { Mutation } from 'vuex-class'
import { leftNav } from './leftNavTpl'
import cache from '@/common/cache';

@Component
export default class Nav extends Vue {
  subMenuPath = ''
  menus = []
  authList = []

  async mounted () {
    const menuPath = localStorage.getItem('subMenuPath')
    const currentPath = window.location.pathname

    if (menuPath && (menuPath === currentPath)) {
      this.subMenuPath = menuPath;
    } else {
      this.subMenuPath = currentPath
      this.setCacheMenuName()
    }
    this.updateMenus();
  }

  go (path) {
    if (this.$route.path !== path) this.$router.push({ path });
  }

  handleSubMenu (path) {
    this.subMenuPath = path
    this.setCacheMenuName()
    this.go(path)
  }

  setCacheMenuName () {
    localStorage.setItem('subMenuPath', this.subMenuPath)
  }

  async updateMenus() {
    const resPermission = await this.$rest.login.getEmployeePermission();
    let authList = [];
    if (resPermission.code === 0) {
      authList = resPermission.data;
      cache.setLocalStorageData('auth_list', authList);
    }
    // 根据权限列表确定展示的导航
    const menus = JSON.parse(JSON.stringify(leftNav));
    let menusInfo = []
    menus.forEach((menu, index) => {
      if (!authList.includes(menu.auth)) return;
      let currentMenu = {
        name: menu.name,
        icon: menu.icon,
        path: menu.path,
        submenus: []
      }
      if (menu.submenus) {
        menu.submenus.forEach((subMenu, subIndex) => {
          if (authList.includes(subMenu.auth)) currentMenu.submenus.push(subMenu);
        });
      }
      menusInfo.push(currentMenu);
    });
    this.menus = menusInfo;
  }

  beforeDestroy () {
    localStorage.setItem('subMenuPath', '')
  }
}
