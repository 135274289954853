export const leftNav: Menus[] = [
  {
    name: '车辆管理',
    icon: 'lx-icon-car',
    path: '',
    hasAuth: true,
    auth: 'car_manage',
    submenus: [{
      name: '车辆入库管理',
      path: '/carManage/enterWarehouse/index',
      hasAuth: true,
      auth: 'view_car'
    }, {
      name: '车辆库存管理',
      path: '/carManage/stock/index',
      hasAuth: true,
      auth: 'view_car'
    }]
  },
  {
    name: '数据中心',
    icon: 'lx-icon-data',
    path: '',
    hasAuth: true,
    auth: 'data_statistic',
    submenus: [{
      name: '每日盘点报表',
      path: '/dataCenter/dailyInventoryReport',
      hasAuth: true,
      auth: 'view_data'
    }]
  },
  {
    name: '车商备案',
    icon: 'lx-icon-record',
    path: '',
    hasAuth: true,
    auth: 'dealer_manage',
    submenus: [{
      name: '全部备案车商',
      path: '/dealer/index',
      hasAuth: true,
      auth: 'dealer.view'
    }, {
      name: '创建备案车商',
      path: '/dealer/addDealer',
      hasAuth: true,
      auth: 'dealer.operate'
    }]
  },
  {
    name: '门店管理',
    icon: 'lx-icon-account',
    path: '',
    hasAuth: true,
    auth: 'micro_structure',
    submenus: [{
      name: '组织架构',
      path: '/operate/framework',
      hasAuth: true,
      auth: 'employee.view'
    }, {
      name: '角色权限',
      path: '/operate/rolepermission',
      hasAuth: true,
      auth: 'role.view'
    }]
  },
]

export interface Menus {
  name: string,
  icon?: string,
  path?: string,
  hasAuth?: boolean,
  auth?: string | Array<string>,
  submenus?: Menus[]
}
