import Vue from 'vue';
import axios from 'axios';
import Ajax from '@lx-frontend/ajax';
import { Message } from 'element-ui';
import interceptors from './interceptors';
import rest from './rest'

const showTips = (tips) => {
  Message({
    type: 'error',
    message: tips,
  });
};

Vue.use(Ajax);
const baseUrl = '/api/v1'
// 实例化配置
const options = {
  baseUrl,
  rest,
  requestInstance: axios,
  ...interceptors,
};

// 实例化
const ajax = new Ajax(options);

// code !== 0时，提示错误的函数, ajax.showTips(message)
ajax.extend({ showTips });

export default ajax;
