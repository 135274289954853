/**
 * vuex 状态管理
 */
import Vue from 'vue';
import Vuex from 'vuex';
import ajax from '@/ajax/index';
import cache from '@/common/cache';

const rest = ajax.$rest;

Vue.use(Vuex);
const initState = {
  direction: 'forward',
  hideNav: false, // 默认展示导航条需要
  router: '/', // 当前路由,
  redirect: '', // 重定向路由
  authList: [], // 权限列表

  userId: '', // 用户id
  userName: '', // 用户名
  centerName: '',
  cdnCommon: process.env.CDN_COMMON, // 通用js库文件的cdn前缀
  centerList: []
};

const store = new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  state: initState,
  modules: {
  },
  mutations: {
    UPDATE_DIRECTION (state, direction) {
      state.direction = direction;
    },
    UPDATE_NAV (state, hideNav) {
      state.hideNav = hideNav;
    },
    UPDATE_ROUTER (state, router) {
      state.router = router;
    },
    UPDATE_REDIRECT (state, redirect) {
      state.redirect = redirect;
    },
    UPDATE_CENTER_LIST (state, centerList) {
      state.centerList = centerList;
    },
    // 更新用户信息
    UPDATE_USERINFO (state, data) {
      if (data) {
        state.userName = data.user_name;
        state.centerName = data.center_name;
        state.centerList = data.center_list;
      }
    },
  },
  actions: {
    async UPDATE_USERINFO({ commit }) {
      const { code, data } = await rest.login.getUserInfo();
      commit('UPDATE_USERINFO', {
        user_name: data.current_info.employee.name,
        center_name: data.current_info.center.name,
        center_list: data.center_list
      });
    },
    UPDATE_CENTER_LIST ({ commit}, params) {
      return rest.login.postCheckVerifyCode(params).then(res => {
        if (res.code === 0) {
          commit('UPDATE_CENTER_LIST', res.data)
        }
        return Promise.resolve(res);
      })
    }
  },
  getters: {
    direction (state) {
      return state.direction;
    },
    hideNav (state) {
      return state.hideNav;
    },
    router (state) {
      return state.router;
    },
    userId (state) {
      return state.userId;
    },
    centerList (state) {
      return state.centerList;
    },
    userName (state) {
      return state.userName;
    },
    centerName (state) {
      return state.centerName;
    }
  },
});
ajax.extend({ $store: store });
export default store;
