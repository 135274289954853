import cache from '@/common/cache';
import ajax from './index';
import { Message } from 'element-ui';
import { reportHttpException } from '@lx-frontend/report'

/**
 * http 异常上报
 * @param {object} res 请求结果
 */
function report(res) {
  const { config, data, headers, status } = res

  const request = {
    url: config.url,
    method: config.method,
    header: config.headers,
    body: config.data,
    timeout: config.timeout
  }

  const response = {
    status,
    header: headers,
    data
  }

  const extra = {}

  reportHttpException({
    request,
    response,
    extra
  })
}

function normalizeRes(res) {
  const { data = {} } = res;
  if (res && typeof data === 'string') {
    res.data = {
      code: -1,
      message: res.statusText,
    };
  } else if (data.code && data.message) {
    return res;
  } else {
    res = {
      data: {
        code: -1,
        message: '未知错误',
      },
    };
  }
  return res;
}

async function operNoneLogin (ctx) {
  if (!ctx.logining) { // 只使第一次401的hash
    ctx.logining = true;

    // 避免第一次登录的用户重复进入登录页面
    if (!window.location.pathname.startsWith('/login') && !(window.location.pathname === '/')) {
      // 保存服务商地址
      const { pathname, hash, href } = window.location;

      if (hash) {
        ctx.next = encodeURIComponent(hash);
      }

      let redirect = '';
      redirect = `/login?${ctx.next}`;
      ctx.$store.commit('UPDATE_REDIRECT', redirect);
    } else {
      ctx.logining = false;
    }
  }
  return true;
}

const requestInterceptor = (ctx: typeof ajax) => (config) => {
  try {
    const token = cache.getLocalStorageData('access_token')
    if (token) config.headers['Authorization'] = token;
    return config;
  } catch (e) {
    console.log('requestInterceptor catch:', e);
  }
};

const responseErrorInterceptor = (ctx: typeof ajax) => (err) => {
  const { response } = err;
  if (response) {
    const { status } = response;
    // 异常上报
    if (status !== 401) {
      report(response)
    }
    switch (status) {
      case 404:
        Message.error('请求发生404错误');
        break;
      case 500:
        Message.error('请求发生500错误');
        break;
      case 504:
        Message.error('请求超时');
        break;
      case 400: // 用户没有csrf-token
      case 401: // 用户没有登录态
        // Message.error('未登录');
        operNoneLogin(ctx);
        break;
      default:
        Message.error(`error:${err.response.status}`)
        
        break;
    }
    const res = normalizeRes(err.response);
    // return Promise.reject(res.data); // 返回接口返回的错误信息
    return res;
  }
  return null;
};

export default {
  requestInterceptor,
  responseErrorInterceptor
};
