/**
 * 路由
 */
import cache from '@/common/cache';
const checkIsLogin = (to, from, next) => {
  const token = cache.getLocalStorageData('access_token');

  if (token) {
    const authList = cache.getLocalStorageData('auth_list') || [];
    if (authList.length === 0) {
      next('/index')
    } else {
      next('/carManage/enterWarehouse/index')
    }
  } else {
    next('/login')
  }

  next()
}
export default {
  mode: 'history',
  routes: [{ // 默认打开页面
      path: '/',
      beforeEnter: checkIsLogin,
      meta: {
        isHideLeftNav: true
      }
    },
    { // 首页
      path: '/index',
      component: resolve => import(/* webpackChunkName: "entry" */ '../modules/Index').then(resolve),
      meta: {
        title: '立新二手车中心',
      },
    }
  ],
};
